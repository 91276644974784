/*Fonts*/

@font-face {
	font-family: 'AllianzNeo';
	src: url('../public/fonts/AllianzNeo-Regular.ttf');
}

@font-face {
  font-family: 'AllianzNeo-Light';
  src: url('../public/fonts/AllianzNeo-Light.ttf');
}

@font-face {
  font-family: 'AllianzNeo-SemiBold';
  src: url('../public/fonts/AllianzNeo-SemiBold.ttf');
}

@font-face {
  font-family: 'AllianzNeo-Bold';
  src: url('../public/fonts/AllianzNeo-Bold.ttf');
}

html {
  font-size: 14px;
  position: relative;
  min-height: 100%;
  min-width: 360px;
}

body {
  background: var(--az-very-dark-blue);
}

img.az-pay-left {
  width: 140.6px;
  height: 58px;
  margin: 8px 173.4px 24px 0;
  object-fit: contain;
}

img.az-pay-center {
  width: 169px;
  height: 69px;
  margin: 8px 0px 0px 0;
  object-fit: contain;
}

#card {
  position: relative;
  width: 339px;
  display: block;
  margin: 40px auto;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
}

.pay-recommended {
  position: absolute;
  left: -0.5px;
  top: -1px;
  background: var(--az-very-dark-blue);
  text-align: center;
  border-radius: 4.25px 0px 10px 0px;
  color: white;
  padding: 1px 5.25px 3px 5px;
  font-size: 10px;
}

#upper-side {
  padding: 2em;
  display: block;
  color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.upper-side-failed {
  background-color: #d72828;
}

.upper-side-success {
  background-color: #35dc78;
}

#checkmark {
  font-weight: lighter;
  fill: white;
  margin: -3.5em auto auto 31px;
}

#status {
  font-weight: lighter;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1em;
  margin-bottom: 0;
  margin-top: 10px;
  color: white;
}

#lower-side {
  padding: 2em 2em 5em 2em;
  background: #fff;
  display: block;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 340px;
}

#message {
  margin-top: -.5em;
  color: #757575;
  letter-spacing: 1px;
}

#contBtn {
  position: relative;
  top: 1.5em;
  text-decoration: none;
  background: #8bc34a;
  color: #fff;
  margin: auto;
  padding: .8em 3em;
  -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
  border-radius: 25px;
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
}

#contBtn:hover {
    -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
    -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
    box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.hide-div {
  display: none;
}

.large {
  max-width: 1000px !important;
}

.az-center {
  text-align: center;
  justify-content: center;
  vertical-align: middle;
}

.container.transparent {
  background-color: transparent;
}

.container {
  max-width: 460px;
  background: #FFFFFF;
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 30px;
  border-radius: var(--az-border-radius);
}

.az-pay-card {
  background-color: var(--az-card-bg);
  border-radius: var(--az-border-radius);
  padding: 35px 10% 35px 10%;
  border: 0;
}

.az-pay-recommended-btn {
  position: absolute;
  top: 20px;
  left: 10%;
  padding: 1px 10px;
  border-radius: 3px;
  background-color: #16c894;
  text-align: center;
  vertical-align: middle;
}

.az-pay-recommended-btn span {
  font-family: AllianzNeo-Bold;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  vertical-align: middle;
  display: block;
  color: #fff;
}

.az-pay-title {
  margin: 0px 3px 24px 0;
  font-family: AllianzNeo-Light;
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #414141;
}

.az-pay-subtitle {
  max-width: 700px;
  margin: 0px 0px 24px 0px;
  font-family: AllianzNeo-SemiBold;
  font-size: 19px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  color: #414141;
}

.az-pay-agreement-label {
  max-width: 882px;
  width: calc(100% - 45px);
  font-family: AllianzNeo;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #414141;
  text-align: left;
}

.az-pay-notice {
  text-decoration: underline;
  color: #0065ae;
  font-style: italic;
}

.az-pay-CB {
  width: 20px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 3px;
  border: solid 1px #aaabac;
  background-color: #fff;
}

.az-pay-loadingMessage {
  width: 300px;
  margin: 42.6px 0 0;
  font-family: AllianzNeo;
  font-size: 19px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  color: #414141;
}

.az-pay-card-title {
  max-width: 230px;
  margin: 16px 10px 8px 0;
  font-family: AllianzNeo-SemiBold;
  font-size: 19px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  color: #414141;
  position: absolute;
}

.az-pay-description {
  margin: 80px 0px 68px 0;
  font-family: AllianzNeo-Light;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #414141;
}

.az-pay-select {
  width: 80%;
  position: absolute;
}

.az-pay-select-btn {
  height: 35px;
  margin: 0 0 0 0.1px;
  border-radius: 4px;
  background-color: var(--az-btn-bg);
  bottom: 20px;
}

.az-pay-select-btn span {
  margin: 7px 0.1px 8px 0;
  font-family: AllianzNeo;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  display: block;
}

.page-container-header {
  margin-bottom: 30px;
}

hr {
  box-shadow: 0 -3px 5px 0.1px;
}

:root {
  --az-light-blue: #14A0D3;
  --az-very-dark-blue: #17384f;
  --az-dark-blue: #006192;
  --az-card-bg: #f1f9fa;
  --az-recommended-bg: #16c894;
  --az-btn-bg: #0065ae;
  --az-border-radius: 3px;
}

.right {
  text-align: left;
  margin-left:10px;
}

.pay-span {
  color: var(--az-light-blue);
  font-size: 51px;
  font-weight: 500;
}

.pay-separator {
  font-size: 25px;
  font-weight: 100;
}

.pay-description {
  float: right;
  font-size: 20px;
  margin-top: 40px;
  font-weight: 400;
  font-style: italic;
  margin-right: 10px;
  margin-left: 10px;
}

.left {
  float: left;
  text-align: right;
  color: var(--az-very-dark-blue);
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  margin-top: 25px;
}

.height-zero {
  height: 0px;
}


.pay-card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  min-height: 200px;
  margin-top: 20px;
  cursor: pointer;
  height: 90%;
}

.pay-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.pay-card-header {
  color: var(--az-dark-blue);
  font-size: 20px;
  font-weight: 500;
  padding: 20px;
  line-height: 25px;
  min-height: 90px;
  margin-top: 20px;
}

.pay-card-link {
  text-decoration: none;
  color: var(--az-very-dark-blue);
}

.pay-card-body {
  font-size: 15px;
  padding: 20px;
}

.text-align-left{
  text-align: left;
}

.mb-80 {
  margin-bottom: 80px;
}

.otp-email *{
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
}

.otp-email p { 
  margin:0; 
}


.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 0 20px 20px 20px;
  border: 1px solid #888;
  width: 80%;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media  (max-width: 361px) {
  .container {
      width: 95%;
      padding: 20px;
  }

  .page-container-header {
      margin-bottom: 30px;
  }

  .right {
      margin-left: 5px;
  }
}

.az-pay-error {
  width: 100%;
  min-height: 290px;
  padding: 30px 40px 30px 40px;
  border-radius: 4px;
  border: solid 1px #dc3148;
  background-color: #efe5e9;
}

.az-pay-success {
  width: 100%;
  min-height: 290px;
  padding: 30px 40px 30px 40px;
  border-radius: 4px;
  background-color: #f1f9fa;
}

.az-pay-message-success {
  padding: 10px 10px 10px 10px;
  border-radius: var(--az-border-radius);
  font-family: AllianzNeo-SemiBold;
  background-color: #f1f9fa;
  position: relative;
  bottom: 0;
  left: 0;

}

img.result {
  width: 80px;
  height: 80px;
  margin-bottom: 24px;
  object-fit: contain;
}

.error-message {
  margin: 24px 0px 0px 0px;
  font-family: AllianzNeo-SemiBold;
  font-size: 19px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  text-align: left;
  color: #dc3148;
}

.success-message {
  margin: 24px 0px 0px 0px;
  font-family: AllianzNeo;
  font-size: 19px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  text-align: center;
  color: #414141;
}

.small-font {
  font-size: 15px;
  margin-bottom: 24px; 
}

.az-disabled {
  background-color: #aaabac;
}

/*Important to resize onfido inside container*/
.onfido-sdk-ui-Modal-inner.onfido-sdk-ui-Theme-root {
  position: relative;
  width: 100%;
  height: 600px;
}

.onfido-sdk-ui-Theme-step.onfido-sdk-ui-Theme-defaultLogo.onfido-sdk-ui-Theme-trialBackground {
  background: none;
}

.onfido-sdk-ui-Theme-root.onfido-sdk-ui-Modal-inner {
  border: 0;
}


.onfido-sdk-ui-Theme-root .ods-button.-action--primary {
  background-color: var(--az-btn-bg) !important;
  border-color: var(--az-btn-bg) !important;
}

.onfido-sdk-ui-Welcome-trialMessage p {
  font-family: AllianzNeo;
  font-size: 19px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  color: #414141;
}

.arrow_down-right {
  margin-top: -3px;
}

.az-back-label {
  width: 183px;
  height: 22px;
  margin: 32px 0px 0 4px;
  font-family: AllianzNeo-SemiBold;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #0065ae;
}

.az-link {
  text-decoration: none;
}

.mt-10 {
  margin-top: 10px;
}
.h-50 {
  height: 50px;
}

@media (min-width: 361px) and (max-width: 461px) {

  .container {
      width: 85%;
  }

  .page-container-header {
      margin-bottom: 30px;
  }

  .right {
    margin-left: 5px;
  }  
}

@media (min-width: 461px) and (max-width: 691px) {
  .container {
      width: 80%;
  }

  .page-container-header {
    margin-bottom: 30px;
  }

  .right {
    margin-left: 5px;
  }
}

@media (min-width: 691px) and (max-width: 961px) {
  .container {
    width: 75%;
  }

  .page-container-header {
    margin-bottom: 30px;
  }
  .right {
    margin-left: 5px;
  }
}

@media (max-width : 992px) {
  .card-max-width {
    width:100%
  }
}
